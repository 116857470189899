import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authReducer, authSlice } from "./slices/auth-slice";
import { channelsSlice } from "./slices/channels-slice";
import { editorReducer, editorSlice } from "./slices/editor-slice";
import { goalsReducer, goalsSlice } from "./slices/goals-slice";
import { integrationsReducer, integrationsSlice } from "./slices/integrations-slice";
import { loaderSlice } from "./slices/loader-slice";
import { membershipsReducer, membershipsSlice } from "./slices/memberships-slice";
import { personalizationReducer, personalizationSlice } from "./slices/personalization-slice";
import { popupReducer, popupSlice } from "./slices/popup-slice";
import { preferencesReducer, preferencesSlice } from "./slices/preferences-slice";
import { projectSlice } from "./slices/project-slice";
import { sidebarSlice } from "./slices/sidebar-slice";
import { uploadBudgetReducer, uploadBudgetSlice } from "./slices/upload-budget-slice";
import { workflowsReducer, workflowsSlice } from "./slices/workflows-slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [goalsSlice.name, channelsSlice.name, projectSlice.name, uploadBudgetSlice.name],
};

const reducer = persistReducer(
  persistConfig,
  combineReducers({
    [authSlice.name]: authReducer,
    [popupSlice.name]: popupReducer,
    [goalsSlice.name]: goalsReducer,
    [channelsSlice.name]: channelsSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [loaderSlice.name]: loaderSlice.reducer,
    [sidebarSlice.name]: sidebarSlice.reducer,
    [membershipsSlice.name]: membershipsReducer,
    [personalizationSlice.name]: personalizationReducer,
    [workflowsSlice.name]: workflowsReducer,
    [preferencesSlice.name]: preferencesReducer,
    [editorSlice.name]: editorReducer,
    [uploadBudgetSlice.name]: uploadBudgetReducer,
    [integrationsSlice.name]: integrationsReducer
  }),
);

const makeStore = () => {
  return configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export const store = makeStore();

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
