import { useMemo, type FC } from "react";

import { useAppSelector } from "hooks/appHooks";

import { ReactComponent as GrowRateIcon } from "assets/icons/growRateMarketingIcon.svg";
import { ReactComponent as WalletIcon } from "assets/icons/marketingWalletIcon.svg";
import { ReactComponent as RevenueMarketingIcon } from "assets/icons/revenueMarketingIcon.svg";

import BrainvoySuggest from "./BrainvoySuggest/BrainvoySuggest";
import BudgetAllocation from "./BudgetAllocation/BudgetAllocation";
import classes from "./ExecutiveView.module.scss";
import Recomendations from "./Recomendations/Recomendations";
import { RevenueFormattedValue } from "./RevenueFormattedValue";

interface ExecutiveViewProps {
  openChat: boolean;
}

const ExecutiveView: FC<ExecutiveViewProps> = ({ openChat = false }) => {

  const project = useAppSelector(state => state.project.project);

  const totalBudget = useMemo(() => {
    return project?.project_data?.budget_data?.reduce((acc: number, item: any) => {
      item?.channels?.forEach((item: any) => (acc += item.value_new));
      return acc;
    }, 0);
  }, [project]);

  return (
    <div className={classes.executiveView}>
      <div className='relative w-[100%] flex items-center justify-between flex-grow gap-x-4'>
        <div className='flex flex-1 items-center justify-between h-[88px] bg-[#fff] rounded-2xl pt-4 pr-4 pb-6 pl-6 gap-x-2'>
          <div className='flex flex-col gap-y-2'>
            <p className='text-[#5E636E] text-sm font-normal'>Marketing Budget</p>
            <p className='text-[#14191A] text-2xl font-semibold '>${totalBudget?.toLocaleString("en-US")}</p>
          </div>
          <WalletIcon className='self-start' />
        </div>
        <div className='flex flex-1 items-center justify-between h-[88px] bg-[#fff] rounded-2xl pt-4 pr-4 pb-6 pl-6 gap-x-2'>
          <div className='flex flex-col gap-y-2'>
            <p className='text-[#5E636E] text-sm font-normal'>Projected Revenue</p>

            <RevenueFormattedValue
              className='[&_span]:!text-[#14191A] [&_span]:!text-2xl [&_span]:!font-semibold m-0'
              from={project?.revenue_from}
              to={project?.revenue_to}
            />
          </div>
          <RevenueMarketingIcon className='self-start' />
        </div>
        <div className='flex flex-1 items-center justify-between h-[88px] bg-[#fff] rounded-2xl pt-4 pr-4 pb-6 pl-6 gap-x-2'>
          <div className='flex flex-col gap-y-2'>
            <p className='text-[#5E636E] text-sm font-normal'>Revenue Growth Rate</p>

            <p className='text-[#14191A] text-2xl font-semibold'>
              {project?.revenue_rate ? `${project?.revenue_rate || "--"}% YoY` : <span className='text-[#9EA2AD]'>Not Provided</span>}
            </p>
          </div>
          <GrowRateIcon className='self-start' />
        </div>
      </div>
      <BrainvoySuggest />
      <BudgetAllocation openChat={openChat} />
    </div>
  );
};

export default ExecutiveView;
