import { Menu, MenuButton, MenuHeading, MenuItem, MenuItems, MenuSection } from "@headlessui/react";
import { MenuButtonProps } from "@headlessui/react/dist/components/menu/menu";
import cn from "classnames";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";


import { DropdownStyles, DropdownVariants } from "constants/shared/dropdown.constants";

import { DropdownItem } from "interfaces/shared/dropdown.interfaces";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { Preferences, updateUserPreferences } from "storage/slices/preferences-slice";

import BlurOverlay from "../BlurOverlay";
import Alert from "../toasts";

import { DropdownContentWrapper } from "./DropdownContentWrapper";
import { DropdownItemWrapper } from "./DropdownItemWrapper";
import { DropdownMenuButton } from "./DropdownMenuButton";

import type { AnchorProps } from "@headlessui/react/dist/internal/floating";

interface Props {
  children?: MenuButtonProps<"button">["children"];
  items?: DropdownItem[];
  heading?: string;
  headingClass?: string;
  className?: string;
  placement?: AnchorProps;
  variant?: DropdownVariants;
  customContent?: ReactNode;
  activeId?: string;
  handleIsOpen?: (value: boolean) => void;
  overlay?:boolean,
  category?:string
}

export const Dropdown = ({
  children,
  items,
  heading,
  headingClass,
  className,
  placement,
  variant = DropdownVariants.DEFAULT,
  customContent,
  activeId,
  handleIsOpen,
  overlay,
  category
}: Props) => {
  const preferences = useAppSelector(state => state.preferences);
  const dispatch = useAppDispatch();
  
  return (
    <Menu>
      {({ open, close }) => {
const handleNotifyMe = async()=>{
  if(!category) return
  const prefName = `workflowsComingSoon${category}` as keyof Preferences;
  const newPrefs = {...preferences, preferences: { ...preferences.preferences, [prefName]: true}}
  
  try{
    dispatch(updateUserPreferences(newPrefs));
    Alert("info","We will notify you as soon as it's ready","Coming Soon")
  }catch(err){
    Alert("error","Something went wrong","Error")
  }
  close()
}
        return (
          <DropdownContentWrapper open={open} handleIsOpen={handleIsOpen}>
            
            <MenuButton as={DropdownMenuButton}>{children}</MenuButton>
            {open && ((items?.length && !customContent) || customContent) ? (
              <MenuItems
                anchor={placement || "bottom"}
                className={twMerge(
                  "bg-white rounded-xl py-1.5 px-1 mb-8 shadow-[0px_4px_29.6px_0px_#00000012] z-[100]",
                  DropdownStyles[variant]?.menuItems,
                  className,
                )}
              >
                <BlurOverlay className='bg-white/50' isShown={overlay} >
                  <div className="flex flex-col items-center justify-center h-full w-full px-2">
                  <p 
                  className="text-lg text-purple font-semibold bg-white/40" 
                  style={{boxShadow: "0px 0px 20px 20px #ffffff",backgroundColor:"white"}}>
                    Coming Soon...
                    </p>
                  <p className="text-sm text-grey-500 font-medium text-center mt-3" 
                  style={{boxShadow: "0px 0px 15px 15px #ffffff",backgroundColor:"white"}}>
                    {"Get notified as soon as it's ready"}
                    </p>
                  <button className="bg-purple rounded-xl py-1 px-4 text-white mt-3" onClick={handleNotifyMe}>
                    <p className="text-sm font-regular">Notify me</p>
                  </button>
                  </div>
                </BlurOverlay>
                <MenuSection className={DropdownStyles[variant]?.menuSection}>
                  {heading && (
                    <MenuHeading className={"text-xs font-medium text-[#717684] py-1 px-2" + " " + headingClass}>{heading}</MenuHeading>
                  )}
                  <>
                    {customContent}
                    {items?.length
                      ? items.map(({ id, action, href, label, icon: Icon }) => (
                          <MenuItem key={id}>
                            <DropdownItemWrapper
                              id={id}
                              href={href}
                              action={action}
                              className={twMerge(
                                cn("flex justify-between transition-all duration-200", DropdownStyles[variant]?.menuItem, {
                                  "text-[#766CE4]": id === activeId,
                                }),
                              )}
                            >
                              {Icon && <Icon className='mt-1' />}
                              {label}
                            </DropdownItemWrapper>
                          </MenuItem>
                        ))
                      : null}
                  </>
                </MenuSection>
              </MenuItems>
            ) : null}
          </DropdownContentWrapper>
        );
      }}
    </Menu>
  );
};
