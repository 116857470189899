import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonVariants } from "constants/shared/button.constants";
import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { handleProjectForm } from "storage/slices/project-slice";

import { ReactComponent as LeftIcon } from "assets/icons/arrowLeft.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrowRight.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrowRightIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendarIcon.svg";

import { generateUniqueId } from "utils";

import { TypographyOptions, TypographyVariants } from "../../../../constants/shared/typography.constants";
import { Button } from "../../buttons/Button";
import Alert from "../../toasts";
import { Typography } from "../../Typography";

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function DatePicker() {
  const dispatch = useAppDispatch();
  const projectData = useAppSelector(state => state.project.newProjectForm);

  const currentMonth = months[new Date().getMonth()];
  const currentYear = new Date().getFullYear()

  const [openRange, setOpenRange] = React.useState(false);
  const [rangeStyle, setRangeStyle] = React.useState({
    budget_date_from: "",
    budget_date_to: "",
    yearFrom: "",
    yearTo: "",
  });

  const [yearFrom, setYearFrom] = React.useState(new Date(projectData.budget_date_from || "").getFullYear() || new Date().getFullYear());
  const [yearTo, setYearTo] = React.useState(
    new Date(projectData.budget_date_from || "").getFullYear() + 1 || new Date().getFullYear() + 1,
  );
  const [dispatchData, setDispatchData] = React.useState<any>({});

  const onCancel = () => {
    setRangeStyle({
      budget_date_from: "",
      budget_date_to: "",
      yearFrom: "",
      yearTo: "",
    });
    dispatch(handleProjectForm({ ...projectData, budget_date_to: null, budget_date_from: null }));
    setOpenRange(false);
  };

  const handleSetDate = () => {
    dispatch(handleProjectForm({ ...projectData, ...dispatchData }));
    setOpenRange(!openRange);
  };

  const handleCloseRange = () => {
    setOpenRange(!openRange);
  };

  function generateMonthsForYears(startYear: number, endYear: number) {
    const yearMonthPairs = [];

    for (let year = startYear; year <= endYear; year++) {
      for (const month of months) {
        yearMonthPairs.push({ year, month });
      }
    }

    return yearMonthPairs;
  }

  // function setLastDayOfMonth(dateString: string): string {
  //   const date = new Date(dateString)
  //   date.setMonth(date.getMonth() + 1)
  //   date.setDate(0)
  //   return date.toISOString()
  // }

  const selectRangeDateHandler = (date: { year: number; month: string }, key: string) => {
    const parsedDate = parseDate(date);
    // const parsedDateAsDate = new Date(parsedDate)
    const parsedDateAsDate = parsedDate;

    setRangeStyle(prev => {
      if (prev.budget_date_from) {
        const startDate = new Date(parseDate({ year: +prev.yearFrom, month: prev.budget_date_from }));
        const endDate = new Date(parsedDate);
        const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
        const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365);

        if (diffYears > 1) {
          Alert("info", "The date range should not exceed 1 year", "Info");
          return prev;
        }
      }
      if (prev.budget_date_from && prev.budget_date_to && parsedDate > parseDate({ year: +prev.yearTo, month: prev.budget_date_to })) {
        setDispatchData({ ...projectData, budget_date_to: parsedDate });
        return { ...prev, budget_date_to: date.month, yearTo: `${date.year}` };
      } else if (prev.budget_date_from && prev.budget_date_to) {
        dispatch(
          handleProjectForm({
            ...projectData,
            budget_date_from: parsedDateAsDate,
            budget_date_to: null,
          }),
        );
        return {
          ...prev,
          budget_date_from: date.month,
          budget_date_to: "",
          yearFrom: `${date.year}`,
          yearTo: "",
        };
      } else if (!prev.budget_date_from) {
        setDispatchData({ ...projectData, budget_date_from: parsedDateAsDate });
        return { ...prev, budget_date_from: date.month, yearFrom: `${date.year}` };
      } else if (prev.budget_date_from && parsedDate < parseDate({ year: +prev.yearFrom, month: prev.budget_date_from })) {
        setDispatchData({ ...projectData, budget_date_from: parsedDateAsDate });
        return { ...prev, budget_date_from: date.month, yearFrom: `${date.year}` };
      } else if (prev.budget_date_from && parsedDate > parseDate({ year: +prev.yearFrom, month: prev.budget_date_from })) {
        setDispatchData((dispatchData: any) => ({
          ...projectData,
          budget_date_from: dispatchData.budget_date_from,
          budget_date_to: parsedDate,
        }));
        return { ...prev, budget_date_to: date.month, yearTo: `${date.year}` };
      } else {
        return prev;
      }
    });
  };

  function parseDate({ year, month }: { year: number; month: string }): string {
    // const monthNumber = new Date(Date.parse(month + '1, 2012')).getMonth()
    // const date = new Date(Date.UTC(year, monthNumber, 1))
    // return date.toISOString().split('T')[0]
    const date = dayjs(`${year.toString()} ${month}`).format("YYYY-MM");
    return date;
  }

  function getMonthName(dateString: Date | undefined | null) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "long" });
  }

  function getYear(dateString: Date | number | undefined | null) {
    if (!dateString) return new Date().getFullYear();
    const date = new Date(dateString);
    return date.getFullYear();
  }

  useEffect(() => {
    if (projectData.budget_date_from || projectData.budget_date_to) {
      const from = dayjs(projectData.budget_date_from).format("MMMM");
      const to = dayjs(projectData.budget_date_to).format("MMMM");
      const yearFrom = Number(dayjs(projectData.budget_date_from).format("YYYY"));
      const yearTo = Number(dayjs(projectData.budget_date_to).format("YYYY"));
      setRangeStyle({
        budget_date_from: from,
        budget_date_to: to,
        yearFrom: `${yearFrom}`,
        yearTo: `${yearTo}`,
      });
    }
  }, []);

  const [placeholderText, setPlaceholderText] = useState("Select your budget period");

  useEffect(() => {
    const updatePlaceholder = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1435) {
        setPlaceholderText("Select period");
      } else {
        setPlaceholderText("Select your budget period");
      }
    };
    updatePlaceholder();
    window.addEventListener("resize", updatePlaceholder);
    return () => window.removeEventListener("resize", updatePlaceholder);
  }, []);

  return (
    <>
      <label className='flex flex-col max-w-[325px] w-full gap-y-1 ml-0 2xl:ml-[56px]'>
        <div className='text-sm font-semibold'>Budget period</div>

        <div className='relative flex items-center text-[#1F2228] font-medium text-sm  cursor-pointer '>
          {rangeStyle.yearFrom && (
            <div className='flex left-4 absolute top-1/2 transform -translate-y-1/2 items-center justify-between gap-x-1'>
              <div className='h-9 min-w-20 flex items-center rounded-xl bg-[#F7F7F8] py-2 px-[11px] text-xs font-medium text-[#717684]'>
                {rangeStyle.yearFrom ? `${rangeStyle.budget_date_from} ${rangeStyle.yearFrom}` : "From"}
              </div>
              <ArrowIcon />
              <div className='h-9 min-w-20 flex items-center rounded-xl bg-[#F7F7F8] py-2 px-[11px] mr-1 text-xs font-medium text-[#717684]'>
                {rangeStyle.yearTo ? `${rangeStyle.budget_date_to} ${rangeStyle.yearTo}` : "To"}
              </div>
            </div>
          )}

          {!rangeStyle.yearFrom && (
            <div className='flex left-4 absolute top-1/2 transform -translate-y-1/2 items-center text-[#9EA2AD] text-[14px] font-medium'>
              {placeholderText}
            </div>
          )}
          <input
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleCloseRange();
            }}
            onChange={() => ""}
            className={twMerge(INPUT_SHARED_CLASSNAME, "py-[9px] px-3.5")}
          />

          <span className='absolute top-1/2 transform -translate-y-1/2 right-[13px]'>
            <CalendarIcon className='w-6 h-6' />
          </span>

          {openRange ? (
            <div className=' overflow-hidden absolute z-20 top-[110%] bg-[#fff] pt-1 pb-[10px] right-0 divide-y divide-gray-100 rounded-[16px] shadow-[-7px_0px_69.5px_0px_rgba(0,0,0,0.11)] w-[536px]'>
              <div className='flex flex-col w-full gap-y-3'>
                <div className='flex items-center justify-between w-full text-xs font-bold border-b-[1px] border-[#F1F0FC]'>
                  <div className='flex flex-col items-center justify-between w-full text-xs font-bold border-r-[1px] border-[#F1F0FC] h-full'>
                    <div className='flex items-center justify-between w-full p-2 pt-0'>
                      <LeftIcon
                        className='cursor-pointer'
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setYearFrom(prev => prev - 1);
                        }}
                      />{" "}
                      <div className='flex items-center justify-center gap-x-2 text-[13px]'>{yearFrom}</div>
                      <RightIcon
                        className='cursor-pointer'
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();

                          setYearFrom(prev => prev + 1);
                        }}
                      />
                    </div>
                    <div className='flex flex-wrap items-center justify-center relative px-2'>
                      {generateMonthsForYears(yearFrom, yearFrom).map((date, i) => {
                        const from = months.indexOf(rangeStyle.budget_date_from);
                        const to = months.indexOf(rangeStyle.budget_date_to);
                        const isBefore = dayjs(`${date.month}, ${date.year}`).isBefore(dayjs(), "year");

                        return (
                          <div key={generateUniqueId()} className={`w-1/3 rounded-lg text-center text-xs font-medium pb-[4px] border-[1px] ${(date?.month === currentMonth && date?.year === currentYear) ? "border-[#D5D1F7]" : "border-white"}`}>
                            <div
                              className={`flex items-center justify-center px-5 py-2 cursor-pointer text-[#1F2228] ${TypographyOptions[TypographyVariants.SM_MEDIUM].className} ${
                                rangeStyle.budget_date_from === date.month && +rangeStyle.yearFrom === date.year
                                  ? "bg-[#766CE4] !text-[#fff] rounded-l-lg"
                                  : rangeStyle.budget_date_to === date.month && +rangeStyle.yearTo === date.year
                                    ? "bg-[#766CE4] !text-[#fff] rounded-r-lg"
                                    : (+rangeStyle.yearTo > date.year && from < i && +rangeStyle.yearFrom === date.year) ||
                                        (from < i && to >= i && +rangeStyle.yearFrom === date.year)
                                      ? "bg-[#F1F0FC] text-black"
                                      : ""
                              }`}
                              style={isBefore ? { opacity: "0.5" } : {}}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                !isBefore && selectRangeDateHandler(date, "budget_date_from");
                              }}
                            >
                              {date.month}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className='flex flex-col items-center justify-between w-full text-xs font-bold h-full'>
                    <div className='flex items-center justify-between w-full text-xs font-bold p-2'>
                      <LeftIcon
                        className='cursor-pointer'
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setYearTo(prev => prev - 1);
                        }}
                      />{" "}
                      <div className='flex items-center justify-center gasp-x-2'>{yearTo}</div>
                      <RightIcon
                        className='cursor-pointer'
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setYearTo(prev => prev + 1);
                        }}
                      />
                    </div>
                    <div className='flex flex-wrap items-center justify-center relative px-2'>
                      {generateMonthsForYears(yearTo, yearTo).map((date, i) => {
                        const from = months.indexOf(rangeStyle.budget_date_from);
                        const to = months.indexOf(rangeStyle.budget_date_to);
                        return (
                          <div key={generateUniqueId()} className='w-1/3 text-center text-xs font-medium pb-[4px]'>
                            <div
                              className={`flex items-center justify-center px-5 py-2 cursor-pointer text-[#1F2228] ${
                                rangeStyle.budget_date_from === date.month && +rangeStyle.yearFrom === date.year
                                  ? "bg-[#766CE4] !text-[#fff] rounded-l-lg"
                                  : rangeStyle.budget_date_to === date.month && +rangeStyle.yearTo === date.year
                                    ? "bg-[#766CE4] !text-[#fff] rounded-r-lg"
                                    : +rangeStyle.yearTo > date.year ||
                                        (+rangeStyle.yearFrom < date.year && from >= i && +rangeStyle.yearTo === date.year && to >= i) ||
                                        (from < i && to >= i && +rangeStyle.yearTo === date.year)
                                      ? "bg-[#F1F0FC] text-black"
                                      : ""
                              }`}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                selectRangeDateHandler(date, "budget_date_from");
                              }}
                            >
                              {date.month}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className=' relative flex justify-between gap-x-2 p-1 px-4 pb-1 w-full'>
                  <div className='flex items-center gap-x-3 w-[50%] pl-[8px]'>
                    <div className='h-9 min-w-20 flex items-center rounded-xl bg-[#F7F7F8] py-2 px-[11px] text-xs font-medium text-[#717684]'>
                      {rangeStyle.yearFrom ? `${rangeStyle.budget_date_from} ${rangeStyle.yearFrom}` : "From"}
                    </div>
                    <ArrowIcon />
                    <div className='h-9 min-w-20 flex items-center rounded-xl bg-[#F7F7F8] py-2 px-[11px] text-xs font-medium text-[#717684]'>
                      {rangeStyle.yearTo ? `${rangeStyle.budget_date_to} ${rangeStyle.yearTo}` : "To"}
                    </div>
                  </div>
                  <div className='flex items-center justify-end gap-x-1 w-[50%]'>
                    <Button
                      variant={ButtonVariants.BORDERED}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onCancel();
                      }}
                      className='max-h-9 w-1/2 text-[#1F2228] text-sm font-semibold before:border-[#D5D1F7]'
                    >
                      Cancel
                    </Button>

                    <Button
                      variant={ButtonVariants.SMALL}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleSetDate();
                      }}
                      className='w-1/2 whitespace-nowrap'
                    >
                      Set Date
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </label>
    </>
  );
}
