import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getConnectedSourceList, getConnectSourceList } from "api/integrations";

interface ConnectSources  {
    integration_id:number,
    display_name:string,
    description:string,
    logo:string,
    category:string,
    scope:string[],
    enabled:boolean,
    supportsRefreshToken:boolean,
    service_id:number | null
}
interface ConnectedSources{
    connection_id: number,
        user_id: number,
        integration_id: number,
        credential_id: number,
        status: string,
        enabled: true,
        integration_external_id: string,
        integration_data: {
            name: string,
            s3_path: string,
            refresh_token: string
        }
}
interface IntegrationStates {
    connectSources:ConnectSources[],
    connectedSources:ConnectedSources[],
    loading:boolean,
    error:null | string
}

const initialState: IntegrationStates = {
    connectSources:[],
    connectedSources:[],
    loading:false,
    error:null
};
export const getAllConnectSources = createAsyncThunk<any>("integrations/getAllConnectSources", async () => {
    try {
      const response = await getConnectSourceList();
      console.log("Response getConnectSOurceList",response)
      return response
    } catch (error: any) {
      console.error(error);
      return;
    }
});
export const getAllConnectedSources = createAsyncThunk<any>("integrations/getAllConnectedSources", async () => {
    try {
      const response = await getConnectedSourceList();
      console.log("Response getConnectedSOurceList",response)
      return response
    } catch (error: any) {
      console.error(error);
      return;
    }
});

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    
  },
  extraReducers: builder => {
    builder
      .addCase(getAllConnectSources.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllConnectSources.fulfilled, (state, action: any) => {
        state.loading = false;
        state.connectSources = action.payload;
      })
      .addCase(getAllConnectSources.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      })

      .addCase(getAllConnectedSources.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllConnectedSources.fulfilled, (state, action: any) => {
        state.loading = false;
        state.connectedSources = action.payload;
      })
      .addCase(getAllConnectedSources.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      })
  },
});

export const {  } = integrationsSlice.actions;

export const integrationsReducer = integrationsSlice.reducer;
