import { useCallback, useState, createContext } from "react";


import { DashboardLayout } from "shared/layouts/DashboardLayout";

import ConnectSourceList from "./components/ConnectSourceList";
import SideSubBar from "./components/SideSubBar";

type SelectedCategoryType = {
  name: string;
  type:string;
};

export const SourcesContext = createContext<undefined | any>(undefined);

export default function ConnectSources() {
  
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategoryType>({name:"",type:""});

  const changeSelectedCategory = useCallback((name: string,type:string) => {
    setSelectedCategory({name:name, type:type});
  }, []);
  return (
    <DashboardLayout title='Integrations'>
     <div className="flex">
      <SourcesContext.Provider value={{selectedCategory, changeSelectedCategory}}>
        <SideSubBar />
        <ConnectSourceList />
        </SourcesContext.Provider>
     </div>
    </DashboardLayout>
  );
}
