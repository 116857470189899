import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";

import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import DotsIcon from "shared/components/IconsComponents/DotsIcon";
import { Table } from "shared/components/table/Table";

interface Props {
  data: any[];
  selectedId: NumberOrNull;
}

export const ConnectedTable = ({ data, selectedId }: Props) => {
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor("name", {
      header: "Platform Name",
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor("name", {
      header: "Account Name",
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor("date_created", {
      id: "date_created",
      header: "Added",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: info => {
        const value = info.getValue();
        return (
          <div
            className={"text-xs font-semibold px-3 py-1 rounded-full w-fit flex items-center capitalize"}
            style={{ backgroundColor: value === "active" ? "#DFFFE2" : "#F2F9FF", color: value === "active" ? "#02A613" : "#5C8DFF" }}
          >
            <div className='w-1 h-1 mr-1.5 rounded-full' style={{ backgroundColor: value === "active" ? "#02A613" : "#5C8DFF" }} />{" "}
            {value ? value : "Unknown"}
          </div>
        );
      },
    }),
    columnHelper.accessor("message", {
        header: "Message",
        cell: info => info.renderValue()
      }),
      columnHelper.accessor("Action", {
        header: "",
        cell: info => {
            const value = info.getValue();
            return (
              <button
                className={"transform rotate-90 h-6 w-6"}
              >
              <DotsIcon />
              </button>
            );
          }
      }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Table
        className='max-h-[234px]'
        table={table}
        idKey='project_id'
        hover={true}
      />
    </div>
  );
};
