import cn from "classnames";
import { useContext, useEffect, useRef } from "react";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import { SourcesContext } from "pages/connect-sources/ConnectSources";

type Options = {
  display_name: string;
  description: string;
  logo: any;
  onConnect: any;
  disabled: boolean;
};
type Props = {
  item: { type: string; options: Options[] };
};

const SourceCards = ({ item }: Props) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { selectedCategory, changeSelectedCategory }: any = useContext(SourcesContext);
  useEffect(() => {
    if (cardRef.current && selectedCategory.name === item.type && selectedCategory.type === "click") {
      cardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCategory]);
//   useEffect(() => {
//     const observer = new IntersectionObserver(entries => {
//       if (entries[0].isIntersecting) {
//         changeSelectedCategory(item.type, "scroll");
//       }
//     });
//     observer.observe(cardRef.current as Element);
//   }, []);
  const showImage = (logo: any) => {
    const Icon = INTEGRATION_ICONS?.[logo as keyof typeof INTEGRATION_ICONS];
    if (!Icon) return <img src={""} alt='logo' className='w-6 h-6' />;
    return <Icon className='w-6 h-6' />;
  };
  return (
    <div ref={cardRef} key={item.type} className={cn("mb-8")}>
      <p className='text-lg font-semibold text-[#333740] mt-6 mb-4'>{item.type}</p>
      <div className='grid grid-cols-2 gap-6'>
        {item.options.map(option => (
          <div key={option.display_name} className='flex items-center justify-between bg-white rounded-lg p-6 gap-4'>
            <div className='flex items-center gap-x-4'>
              <div className='h-6 w-6'>{showImage(option.logo)}</div>
              <div>
                <p className='text-[15px] font-semibold text-[#1F2228]'>{option.display_name}</p>
                <p className='text-sm font-medium text-[#5E636E] flex-wrap'>{option.description}</p>
              </div>
            </div>

            <button
              className={cn("py-2 px-5 text-sm font-semibold border border-[#D5D1F7] rounded-lg flex-nowrap text-nowrap", {
                "bg-gray-100 text-gray-500": option.disabled,
              })}
              onClick={option.onConnect}
            >
              Connect
            </button>
          </div>
        ))}
        {item.options.length === 0 && <p className='text-sm font-medium text-[#5E636E] flex-wrap'>No matches found in {item.type}</p>}
      </div>
    </div>
  );
};

export default SourceCards;
