import { ReactComponent as AnalyticsIcon } from "../../../assets/icons/navigation-icons/analyticsIcon.svg";
import { ReactComponent as ActiveHomeIcon } from "../../../assets/icons/navigation-icons/homeActiveIcon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/navigation-icons/homeIcon.svg";
import { ReactComponent as IntegrationsIcon } from "../../../assets/icons/navigation-icons/integrationsIcon.svg";
import { ReactComponent as VoyagersIcon } from "../../../assets/icons/navigation-icons/voyagersIcon.svg";
import { ReactComponent as ActiveWalletIcon } from "../../../assets/icons/navigation-icons/walletActiveIcon.svg";
import { ReactComponent as WalletIcon } from "../../../assets/icons/navigation-icons/walletIcon.svg";
import { ReactComponent as ActiveWorkflowIcon } from "../../../assets/icons/navigation-icons/workflowActiveIcon.svg";
import { ReactComponent as WorkflowIcon } from "../../../assets/icons/navigation-icons/workflowIcon.svg";
import { ROUTES } from "../../../constants/routes.constants";

export const navConfig = [
  { to: ROUTES.dashboard, title: "Home", Icon: HomeIcon, ActiveIcon: ActiveHomeIcon, beta: false },
  { to: ROUTES.budget, title: "Budget", Icon: WalletIcon, ActiveIcon: ActiveWalletIcon, beta: true },
  { to: ROUTES.workflow, title: "Workflow", Icon: WorkflowIcon, ActiveIcon: ActiveWorkflowIcon, beta: true },
  // {
  //   to: "/integrations",
  //   title: "Integrations",
  //   Icon: IntegrationsIcon,
  //   ActiveIcon: IntegrationsIcon,
  //   beta: true,
  //   dropdown: [
  //     { to: "/integrations/connect-sources", title: "Connect Sources" },
  //     { to: "/integrations/connected-sources", title: "Connected Sources" },
  //   ],
  // },
];

export const soonPagesConfig = [
  { to: "/analytics", title: "Analytics", Icon: AnalyticsIcon, ActiveIcon: AnalyticsIcon, beta: false },
  {
    to: "/integrations",
    title: "Integrations",
    Icon: IntegrationsIcon,
    ActiveIcon: IntegrationsIcon,
    beta: false,
  },
  {
    to: "/voyagers",
    title: "AI Voyagers",
    Icon: VoyagersIcon,
    ActiveIcon: VoyagersIcon,
    beta: false
  },
];
