import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";

import { IChannelData } from "api/budget/types";
import { createProject, initializeProject, updateProject } from "api/projects";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getOneProject, getProjectsList } from "storage/actions/project";
import { setLoading } from "storage/slices/loader-slice";
import { openPopup } from "storage/slices/popup-slice";
import { handleProjectForm, setPoject, setUploadedPoject } from "storage/slices/project-slice";

import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as EditIcon } from "assets/icons/editIcon.svg";

import DescribeProjectForm from "./forms/DescribeProjectForm";
import GoalsProjectForm from "./forms/GoalsProjectForm";
import MarketingStrategyForm from "./forms/MarketingStrategyForm";
import { stepFormConfig } from "./stepFormConfig";

interface ICurrentStep {
  tabIndex: number;
  title: string;
  description: string;
  editStep?: (tabIndex: number) => void;
}

type CurrentStepType = Pick<ICurrentStep, "title">;
type DoneStepType = Pick<ICurrentStep, "title" | "tabIndex" | "editStep">;

const CurrentStep = ({ tabIndex, title, description }: ICurrentStep) => {
  return (
    <li>
      <div className='pr-5 py-5 pl-6 border-[1.5px] border-[#766CE4] rounded-[20px] bg-[#fff] w-[305px] h-[153px]'>
        <div className='flex flex-col justify-start'>
          <div className='text-[#766CE4] font-semibold text-[28px] leading-none'>{tabIndex}</div>
          <div className='text-[#1F2228] font-bold text-[16px] leading-none pt-2.5'>{title}</div>
          <div className='text-[#9EA2AD] font-normal pt-2.5 text-sm'>
            {description.split("<br/>").map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};

const NextStep = ({ title }: CurrentStepType) => {
  return (
    <li>
      <div className='text-[#9EA2AD] font-medium text-base opacity-30 py-3'>{title}</div>
    </li>
  );
};

const DoneStep = ({ title, tabIndex, editStep }: DoneStepType) => {
  return (
    <li>
      <div className=' w-[305px] pr-5 py-5 pl-6 border-[1.5px] border-[#F7F7F8] rounded-3xl bg-[#fff] relative hover:border-[#D5D1F7] hover:border-[1.5px]'>
        <EditIcon className='absolute top-5 right-5 cursor-pointer' onClick={() => editStep && editStep(tabIndex)} />
        <div className='flex flex-col gap-y-2'>
          <div className='text-gray-800 font-semibold text-2xl'>{tabIndex}</div>
          <div className='text-gray-800 font-bold text-base'>{title}</div>
        </div>
      </div>
    </li>
  );
};

export default function StepForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data: any = useAppSelector(state => state.project);
  const file_id: number | null = useAppSelector(state => state.project?.uploadedProject?.file_id) || null;
  const marketingChannels = useAppSelector(state => state.channels.channels);
  const { id } = useParams();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [project_id, setProjectId] = useState<string | undefined>(id);
  const updateProjectData = useCallback((apiData: any) => {
    if(currentStep === 3){
      dispatch(setLoading(true));
    }
    updateProject(apiData, project_id)
      .then(async (res) => {
        await dispatch(getOneProject(Number(project_id)));
        navigate(`/budget/${project_id}/budget-intelligence`);
        dispatch(getProjectsList({ page: 1 }));
      })
      .catch(err => {
        Alert("error", err.message, "Error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [currentStep, project_id, dispatch, navigate]);
  const nextStepHandler = useCallback(() => {
    const { revenue_from, revenue_to, isRevenueUnknown, ...formValue } = data?.newProjectForm || {};

    if (currentStep === 3) {
      const apiData = {
        name: formValue?.name,
        description: formValue?.description,
        audience: formValue?.audience,
        goals: formValue?.goals,
        revenue_from: revenue_from,
        revenue_to: revenue_to,
        revenue_rate: formValue?.revenue_rate,
        isRevenueUnknown: isRevenueUnknown,
        channels: formValue?.channels.map((item: IChannelData) => item.channel_id),
        budget_from: formValue?.budget_from,
        budget_to: formValue?.budget_to,
        budget_date_from: formValue?.budget_date_from,
        budget_date_to: formValue?.budget_date_to,
        ...file_id && {file_id:file_id}
      };
      updateProjectData(apiData);
    }else{
      setCurrentStep(prev => prev + 1);
    }
    
  }, [currentStep, data?.newProjectForm, updateProjectData]);

  // useEffect(() => {
  //   console.log("All the Biggest")
  //   if (data?.uploadedProject && data.method !== "manual") {
  //     console.log("inside manual")
  //     dispatch(
  //       handleProjectForm({
  //         ...data.newProjectForm,
  //         budget_from: data?.uploadedProject?.budget_from,
  //         budget_date_to: data?.uploadedProject?.budget_date_to,
  //         budget_date_from: data?.uploadedProject?.budget_date_from,
  //         budget_to: data?.uploadedProject?.budget_to,
  //         revenue_from: data?.uploadedProject?.revenue_from,
  //         revenue_to: data?.uploadedProject?.revenue_to,
  //         revenue_rate: data?.uploadedProject?.revenue_rate,
  //         goals: [...(data?.newProjectForm?.goals || [])],
  //         channels: [...marketingChannels.filter(item => data?.uploadedProject?.channels?.find((goal: any) => goal === item.channel_id))],
  //       }),
  //     );
  //   }
  // }, [data.method, data.newProjectForm, data?.uploadedProject, dispatch, marketingChannels]);
  const createNewProject = useCallback(() => {
    initializeProject()
      .then(res => {
        setProjectId(res.project_id);
        dispatch(
          handleProjectForm({
            name: "",
            description: null,
            audience: [],
            goals: [],
            budget_from: null,
            budget_to: null,
            budget_date_from: null,
            budget_date_to: null,
            revenue_from: null,
            revenue_to: null,
            revenue_rate: null,
            channels: [],
          }),
        );
      })
      .catch(err => {
        if (err.message === "Your pricing plan does not allow creating new budgets") {
          dispatch(openPopup("MembershipsModal"));
        } else {
          Alert("error", err.message, "Error");
        }
      });
  }, [dispatch]);
  const prefillProjectData = useCallback(() => {
    console.log("Prefill Project Data");
    dispatch(
      handleProjectForm({
        ...data.project,
      }),
    );
  }, [dispatch, data.project]);
  useEffect(() => {
    return () => {
      dispatch(handleProjectForm({}));
      dispatch(setUploadedPoject(null));
    };
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      prefillProjectData();
    } else {
      createNewProject();
    }
  }, [id, prefillProjectData, createNewProject]);
  return (
    <DashboardLayout title='Start Your Project'>
      <div className='flex w-full min-h-full h-full gap-x-7 bg-[#F7F9FB] p-6'>
        <ol className=' min-w-[200px] flex flex-col max-w-[300px] w-full gap-y-4'>
          {stepFormConfig.map(({ tabIndex, title, description }) =>
            currentStep > tabIndex ? (
              <DoneStep key={title + Date.now()} title={title} tabIndex={tabIndex} editStep={setCurrentStep} />
            ) : currentStep === tabIndex ? (
              <CurrentStep key={title + Date.now()} tabIndex={tabIndex} title={title} description={description} />
            ) : (
              <NextStep key={title + Date.now()} title={title} />
            ),
          )}
        </ol>

        {currentStep === 1 && <DescribeProjectForm nextStepHandler={nextStepHandler} />}
        {currentStep === 2 && <GoalsProjectForm nextStepHandler={nextStepHandler} />}
        {currentStep === 3 && <MarketingStrategyForm nextStepHandler={nextStepHandler} />}
      </div>
    </DashboardLayout>
  );
}
