import { Meta } from "interfaces/meta.interfaces";
import { StringOrNumber } from "interfaces/shared-types.interfaces";
import {

} from "interfaces/integrations.interfaces";


import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getConnectSourceList = (): Promise<any[]> => {
  return api(`${BASE_API}/integrations`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
export const getConnectedSourceList = (): Promise<any[]> => {
    return api(`${BASE_API}/connections`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  };

export const generateCredentialForAppIntegration = (id: number): Promise<any> => {
  return api(`${BASE_API}/credentials/auth-url/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

