import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getAllConnectedSources } from "storage/slices/integrations-slice";

import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ConnectedTable } from "./components/ConnectedTable";


export default function ConnectedSources() {
  const dispatch = useAppDispatch();
  const connectedSourcesList = useAppSelector(state => state.integrations.connectedSources);

const filteredData = useMemo(()=>{
    return connectedSourcesList.map(item=> ({...item,...item.integration_data}))
  },[connectedSourcesList])
useEffect(()=>{
  dispatch(getAllConnectedSources())
},[])
  return (
    <DashboardLayout title='Integrations'>
      <div className='bg-[#F7F9FB] rounded-xl flex-1 min-h-full flex flex-col items-start gap-y-6 py-6 px-5'>
      <div className='w-full bg-white rounded-2xl p-6'>
        <ConnectedTable data={filteredData} selectedId={null}/>
        </div>
     </div>
    </DashboardLayout>
  );
}
