import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

import { CHANNEL_FLOW_ICONS } from "constants/channels.constants";

type Item = 
    {channel_id: number, name: string, icon: string}

type Props = {
    value: Item[];
};

const ChannelTagsWrapper = ({value}: Props) => {

    const [hidden, setIsHidden] = useState(true);
        return (
          <div
            className={"flex flex-row flex-wrap"}
          >
            {value.length === 0 && (
              <div className='flex items-center gap-2 px-3 py-1.5 border rounded-full mr-1'>
                <div className='text-sm font-medium text-[#333740] '>None</div>
              </div>
            )}
            {value.map((item:Item, index:number) => {
              if (index > 1) return null;

              const Icon = CHANNEL_FLOW_ICONS?.[item?.icon as keyof typeof CHANNEL_FLOW_ICONS];

              return (
                <div
                  key={index}
                  className={twMerge(
                    "w-fit select-none font-plusJakartaSans whitespace-nowrap border rounded-full  px-3 py-1.5 mr-1 flex items-center mb-1",
                  )}
                >
                  <Icon className='w-4 h-4 mr-1.5' />
                  {item?.name}
                </div>
              );
            })}
            {value.length > 2 && (
              <div className={"flex items-center gap-2 px-3 py-1.5 border rounded-full mr-1 mb-1 relative flex-nowrap justify-center "}   onMouseEnter={() => {
                setIsHidden(false);
              }} onMouseLeave={() => {
                setIsHidden(true);
              }}>
                <div className="text-sm font-medium text-[#333740]">+{value.length - 2}</div>
                {!hidden && <div className={"absolute top-9 flex bg-white rounded-xl p-3 shadow-allAround z-10"}>
                    {value.map((item:Item, index:number) => {
              if (index <= 1) return null;

              const Icon = CHANNEL_FLOW_ICONS?.[item?.icon as keyof typeof CHANNEL_FLOW_ICONS];

              return (
                <div
                  key={index}
                  className={twMerge(
                    "w-fit select-none font-plusJakartaSans whitespace-nowrap border rounded-full  px-3 py-1.5 mr-1 flex items-center mb-1",
                  )}
                >
                  <Icon className='w-4 h-4 mr-1.5' />
                  {item?.name}
                </div>
              );
            })}
                </div>}
              </div>
            )}
          </div>
        );
  
};

export default ChannelTagsWrapper;