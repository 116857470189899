import cn from "classnames";
import { useContext } from "react";

import { SourcesContext } from "../ConnectSources";

type Props = {
  selectedCategory: { name: string ,type:string};
  changeSelectedCategory: (category: string, type:string) => void;
};

const SideSubBar = () => {
  const categoryList = ["CRM", "Social Media", "Accounting & ERP", "E-Commerce", "Data files","Tracking"];
  const { selectedCategory, changeSelectedCategory }:Props = useContext(SourcesContext);
  return (
    <div className='w-52 h-1 relative'>
      <div className='h-full py-5 px-2 bg-white fixed w-52 ml-0.5 mt-0.5 gap-y-8 flex flex-col'>
        <p className='text-sm font-bold text=[#333740] text-center'>By Category</p>
        <div className='flex flex-col gap-y-1'>
          {categoryList.map(item => (
            <button
              key={item}
              onClick={() => changeSelectedCategory(item,"click")}
              className={cn("text-sm font-medium text-[#464A53] px-3 py-1.5 rounded-md", { "bg-[#F7F7F8]": (item === selectedCategory.name || (item === "CRM" && selectedCategory.name === "")) })}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideSubBar;
