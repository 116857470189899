import { useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { generateCredentialForAppIntegration } from "api/integrations";

import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useString } from "hooks/useString";

import { getAllConnectSources } from "storage/slices/integrations-slice";

import SourceCards from "./ConnectSourceList/SourceCards";

const ConnectSourceList = () => {
  const [search, setSearch] = useString("");
  const dispatch = useAppDispatch();
  const connectSourcesList = useAppSelector(state => state.integrations.connectSources);
  const categoryList = ["CRM", "Social Media", "Accounting & ERP", "E-Commerce", "Data files", "Tracking"];

  const handleIntegration = (integration_id: number) => {
    console.log("Integration ID", integration_id);
    if (integration_id === 1) {
      console.log("HubSpot");
      generateCredentialForAppIntegration(integration_id).then(res => {
        console.log(res);
        window.open(res.authorizationUrl);
      });
    }
  };
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const filteredSearchList = useMemo(() => {
    const data: any = {
      CRM: [],
      "Social Media": [],
      "Accounting & ERP": [],
      "E-Commerce": [],
      "Data files": [],
      Tracking: [],
    };
    connectSourcesList.forEach(item => {
      if (item.display_name.toLowerCase().includes(search.toLowerCase())) data[item.category].push(item);
    });
    const newdata = categoryList.map(item => {
      return {
        type: item,
        options: data[item].map((item: any) => {
          return { ...item, logo: item.logo, onConnect: () => handleIntegration(item.integration_id), disabled: false };
        }),
      };
    });
    return newdata;
  }, [search, connectSourcesList]);
  const queryToObject = (query:string) => {
	const parameters = new URLSearchParams(query);
	return Object.fromEntries(parameters.entries());
};
  useEffect(() => {
    const payload = queryToObject(window.location.search.split("?")[1]);
		const state = payload && payload.state;
		const error = payload && payload.error;
        console.log("State",state)
        console.log("Error",error)
    dispatch(getAllConnectSources());
  }, []);
  return (
    <div className='flex-1 h-[5000px] p-6'>
      <p className='text-2xl font-semibold text-[#333740] '>Connect Sources</p>
      <div className="relative">
        <div className='absolute cursor-pointer inset-y-0 start-0 flex items-center ps-3 '>
          <svg style={{ cursor: "pointer" }} width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
              stroke='#2C1A49'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path opacity='0.4' d='M22 22L20 20' stroke='#2C1A49' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
        <input
          type='search'
          className={twMerge("max-w-[325px] py-[9px] pl-10 pr-3.5 ", INPUT_SHARED_CLASSNAME)}
          placeholder='Search budgets'
          required
          value={search}
          onChange={handleSearch}
        />
      </div>
      {filteredSearchList.map((item: any) => (item.options.length > 0 ? <SourceCards item={item} key={item.type} /> : null))}
    </div>
  );
};

export default ConnectSourceList;
